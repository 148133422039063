import Image from 'next/image';

export default function ResourceSearch({
  placeholder,
  onSearchChange,
  searchQuery,
  hideFilter,
  setIsSearchActive,
  setIsFilterActive,
}) {
  const getInputValueClass = () => {
    if (typeof window !== 'undefined') {
      let field = document.getElementById('search-input');

      return field?.value ? ' focus' : ' ';
    }

    return ' ';
  };

  return (
    <div className={'resource-search ' + getInputValueClass()}>
      <div className={'input-container ' + getInputValueClass()}>
        {hideFilter !== undefined ? (
          <>
            <div
              className="img-position"
              style={{ width: '10px', height: '10px' }}
            >
              {/*<Image layout="fill" style={{left: hideFilter ? '0' : '0', paddingLeft: hideFilter ? '0' : '24px'}} src={'/images/search-icon.svg'} alt={'search'} />*/}
              {/*<img style={{left: hideFilter ? '0' : '0', paddingLeft: hideFilter ? '0' : '24px'}} src={'https://res.cloudinary.com/digitalrealty/image/upload/v1678092229/search-icon_zkg3uo.svg'}  alt={'filter'}/>*/}
              <img
                style={{
                  left: hideFilter ? '0' : '0',
                  paddingLeft: hideFilter ? '0' : '0',
                }}
                src={'/images/search-icon.svg'}
                alt={'filter'}
              />
            </div>
            <input
              style={{ marginLeft: hideFilter ? '-22px' : '0' }}
              id={'search-input'}
              className={getInputValueClass()}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              placeholder={placeholder}
            />
          </>
        ) : (
          <>
            <div
              className="img-position"
              style={{ width: '10px', height: '10px' }}
            >
              {/*<Image layout="fill" src={'/images/search-icon.svg'} alt={'search'}/>*/}
              {/*<img src={'https://res.cloudinary.com/digitalrealty/image/upload/v1678092229/search-icon_zkg3uo.svg'}  alt={'filter'}/>*/}
              <img src={'/images/search-icon.svg'} alt={'filter'} />
            </div>
            <input
              size={placeholder?.length + 4}
              onClick={() => {
                if (setIsSearchActive && setIsFilterActive) {
                  setIsSearchActive(true);
                  setIsFilterActive(false);
                }
              }}
              id={'search-input'}
              className={getInputValueClass()}
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              placeholder={placeholder}
            />
          </>
        )}
      </div>
    </div>
  );
}
